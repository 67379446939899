<template>
    <i>
        <span>L'app di H2H è presente sia su android (PlayStore) che su ios (AppStore)</span><br>
        <a href="https://play.google.com/store/apps/details?id=com.h2h.it" target="_blank">ANDROID</a><br>
        <a href="https://apps.apple.com/" target="_blank">IOS</a>
    </i>
</template>

<script>
    export default {
        name: 'FAQ1'
    }
</script>

<style scoped>

</style>

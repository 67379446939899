<template>
    <h-row justify="center">
        <h-col cols="11" md="6">

            <h-row justify="center">
                <h-col cols="auto">
                    <v-img src="/assets/LOGO.png"
                           alt="hand2hand-logo"
                           max-width="100"
                           max-height="100"
                    ></v-img>
                </h-col>
            </h-row>

            <h-row justify="center">
                <h-col cols="auto">
                    <h2>Come possiamo aiutarti?</h2>
                </h-col>
            </h-row>

            <h-row class="mb-3" justify="center">
                <h-col>
                    <base-text-field v-model.trim="search"
                                     label="Faq"
                                     placeholder="Descrivi il tuo problema"
                                     clearable
                                     maxlength="500"
                                     prepend-icon="fa-search"
                    ></base-text-field>
                </h-col>
            </h-row>

            <h-row justify="center">
                <h-col cols="auto">
                    <h2>FAQ</h2>
                </h-col>
            </h-row>

            <h-row justify="center">
                <h-col>
                    <v-card>
                        <v-expansion-panels>
                            <v-expansion-panel v-for="(item, index) in faqs" :key="index">

                                <v-expansion-panel-header>{{ item.title }}</v-expansion-panel-header>

                                <v-expansion-panel-content>

                                    <component v-if="item.custom" :is="item.component"></component>

                                    <i v-else>{{ item.description }}</i>

                                </v-expansion-panel-content>

                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card>
                </h-col>
            </h-row>

        </h-col>
    </h-row>
</template>

<script>
    import HRow from '@/components/aaaGenerics/layout/HRow';
    import HCol from '@/components/aaaGenerics/layout/HCol';
    import BaseTextField from '@/components/aaaGenerics/form/BaseTextField';
    import FAQ1 from '@/components/app/faqs/FAQ1';

    export default {
        name: 'Faq',
        components: {HRow, HCol, BaseTextField},
        data: () => ({
            search: '',
            items: [
                {
                    id: 1,
                    custom: true,
                    title: 'Dove posso scaricare l\'applicazione H2H',
                    description: 'L\'app di H2H è presente sia su android (PlayStore) che su ios (AppStore) ANDROID IOS',
                    component: FAQ1
                }
            ]
        }),
        computed: {
            faqs: function () {

                let filter = this.search || '';
                filter = filter.toUpperCase();

                return this.items.filter(
                    i => i.title.toUpperCase().includes(filter) || i.description.toUpperCase().includes(filter)
                );

            }
        },
        created() {
            this.search = '';
        }
    }
</script>

<style scoped>

</style>
